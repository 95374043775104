import {EnvironmentTypes} from '../app/Enums/environment-types';

export const environment = {
    tenant: EnvironmentTypes.VSA,
    production: true,
    redirectUri: 'https://stage.arp.3d5.at/',
    postLogoutRedirectUri: 'https://stage.arp.3d5.at/',
    arpApiUrl: 'https://arp-api-staging.azurewebsites.net',
    portalUrl: 'https://arp-portal-stage.azureedge.net/?autoredirect=false',
    msalAuthority: 'https://login.microsoftonline.com/a76381c7-ea6e-41f4-8bb8-cb6ec292a8a0',
    msalClientId: '294a34bd-9558-46a9-9d3d-ba65ee090002',
    authScopes: [
        {
            apiUrl: 'https://arp-api-staging.azurewebsites.net/v2_0',
            scope: 'api://0574525e-ccf4-4e73-a019-becf0554da1d/Api.ReadWrite.All'
        }
    ]
};
